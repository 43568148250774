import React, { useState, useEffect } from 'react';

function AnimatedParagraph() {
  const [text, setText] = useState('');
  const fullText = 'We Add Value';
  const [shouldStart, setShouldStart] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldStart(true);
    }, 6000); // Delay of 15 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (shouldStart) {
      const intervalId = setInterval(() => {
        if (text.length < fullText.length) {
          setText((prevText) => prevText + fullText[prevText.length]);
        } else {
          clearInterval(intervalId);
          // Start blinking after the paragraph is complete (if needed)
        }
      }, 100); // Adjust delay as needed

      return () => clearInterval(intervalId);
    }
  }, [shouldStart, fullText, text]);

  return (
    <p className="text-light">
      {text}
    </p>
  );
}

export default AnimatedParagraph;